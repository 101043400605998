@tailwind base;

*{
    --secondary: black;
    --primary: white
}

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    opacity: 0.1;
    background-color: black;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 14px;
    border: 1px solid var(--primary);
}


@tailwind components;
@tailwind utilities;
